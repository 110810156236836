<template>



    <div class=" col-start-1 col-end-13  min-h-screen flex flex-col justify-center items-center ">
        <div class="absolute mx-auto  z-10  tracking-wide">
            <div class="shadow-2xl rounded-lg hover:shadow-2xl transition duration-500 ease-out" >
                        <v-card class="px-5 mt-5" elevation="2">
                            <v-card-text >
                                <div class="flex gap-3 justify-center" >
                                    <span class="text-xl tracking-wider text-gray-600 font-semibold">Realizar</span>
                                    <span class="text-xl tracking-wider purple-main font-semibold">Pago</span>
                                </div>
                                <div>      
                                    <div class="px-5">
                                        <small class="text-gray-400 tracking-wider">Información de la tarjeta</small>
                                        <v-divider></v-divider>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-5 md:px-5 md:grid-cols-1 py-2">
                                        <div>
                                                <label class="text-base">Numero de tarjeta</label>
                                                <input class="input-full-fucsia" id="form-checkout__cardNumber" type="number"  placeholder="Ingrese el numero de tarjeta" />
                                                <!-- <small class="text-xs text-red-600" v-show="$v.user.name.$anyDirty && !$v.user.name.required">* El nombre es requerido</small> -->
                                        </div>
                                    </div>                                    
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-5 md:px-5 md:grid-cols-2 py-2">
                                        <div>
                                                <label class="text-base">Mes de Expiración</label>
                                                <input class="input-full-fucsia" type="text" name="cardExpirationMont" id="form-checkout__cardExpirationMonth" placeholder="Mes de expiración"/>
                                                <!-- <small class="text-xs text-red-600" v-show="$v.user.last_name.$anyDirty && !$v.user.last_name.required">* El apellido es requerido</small> -->
                                        </div>
                                        <div>
                                                    <label class="text-base">Año de Expiración</label>
                                                    <input class="input-full-fucsia" type="text" name="cardExpirationYear" id="form-checkout__cardExpirationYear" placeholder="Año de expiración"/>                            
                                                    <!-- <small class="text-xs text-red-600" v-show="$v.user.second_lastname.$anyDirty && !$v.user.second_lastname.required">* El apellido es requerido</small> -->
                                        </div>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-5 md:px-5 md:grid-cols-1 py-2">
                                            <div>
                                                    <label class="text-base">Titular de la Tarjeta</label>
                                                    <input class="input-full-fucsia" type="text" name="cardholderName" id="form-checkout__cardholderName" placeholder="Titular de la tarjeta"/>
                                                    <!-- <small class="text-xs text-red-600" v-show="$v.user.birthdate.$anyDirty && !$v.user.birthdate.required">* El fecha es requerida</small> -->
                                            </div>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-5 md:px-5 md:grid-cols-2 py-2">
                                        <div>
                                                    <label class="text-base">Correo electronico del titular</label>
                                                    <input class="input-full-fucsia" type="text" name="cardholderName" id="form-checkout__cardholderEmail"/>
                                                    <!-- <small class="text-xs text-red-600" v-show="$v.user.id_cat_sex.$anyDirty && !$v.user.id_cat_sex.required">* El genero es requerido</small> -->
                                            </div>
                                            <div>
                                                    <label class="text-base">Codigo de Seguidad CVV</label>
                                                    <input class="input-full-fucsia" type="number" name="securityCode" id="form-checkout__securityCode" />
                                                    <!-- <small class="text-xs text-red-600" v-show="$v.user.address.$anyDirty && !$v.user.address.required">* La dirección es requerida</small> -->
                                            </div>
                                    </div>
                                    <div class="px-5">
                                        <small class="text-gray-400 tracking-wider">Información de la compra</small>
                                        <v-divider></v-divider>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-5 md:px-5 md:grid-cols-2 py-2">
                                            <div>
                                                    <label class="text-base">Numero de identificación</label>
                                                    <input class="input-full-fucsia" type="text" name="identificationNumber" id="form-checkout__identificationNumber"/>
                                                    <!-- <small class="text-xs text-red-600" v-show="$v.user.phone.$anyDirty && !$v.user.phone.required">* El teléfono es requerido</small> -->
                                            </div>
                                            <div>
                                                    <label class="text-base">Cuotas</label>
                                                    <select class="input-full-fucsia" name="installments" id="form-checkout__installments">
                                                        <option value="1">1 licencia $999</option>
                                                    </select>
                                                    <!-- <small class="text-xs text-red-600" v-show="$v.user.phone.$anyDirty && !$v.user.phone.required">* El teléfono es requerido</small> -->
                                            </div>                                            
                                    </div>
                                    <!-- <div class="px-5">
                                        <small class="text-gray-400 tracking-wider">Credenciales</small>
                                        <v-divider></v-divider>
                                    </div>
                                    <div class="grid grid-cols-1 gap-10 gap-y-2 px-5 md:px-5 md:grid-cols-2 py-2">
                                            <div>
                                                    <label class="text-base">Cuotas</label>
                                                    <select class="input-full-fucsia" name="installments" id="form-checkout__installments">
                                                        <option value="1">1 plazo</option>
                                                    </select>
                                                    <small class="text-xs text-red-600" v-show="$v.user.email.$anyDirty && !$v.user.email.required">* El correo es requerido</small>
                                            </div>
                                            <div>
                                                    <label class="text-base">Contraseña</label> 
                                                    <div class="flex gap-3 items-center">
                                                    <input :type="!ispwd ? 'password': ''" v-model="$v.user.password.$model" class="input-full-fucsia" placeholder="Ingrese la contraseña" />          
                                                        <svg @click="ispwd = !ispwd" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" :class="!ispwd ? 'text-gray-400' : 'text-blue-700'" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                                        </svg>

                                                    </div>
                                                    <small class="text-xs text-red-600" v-show="$v.user.password.$anyDirty && !$v.user.password.required">* La contraseña es requerida</small>
                                            </div>                        
                                    </div>                       -->
                                </div>                          
                            </v-card-text>
                            <v-card-actions>
                            <v-spacer></v-spacer>
                            <button class="bg-fucsia-lighter px-2 py-1 rounded-lg text-white shadow-sm hover:bg-fucsia-dark focus:outline-none">
                                Realizar pago
                            </button>
                            </v-card-actions>
                        </v-card>
            </div>            
        </div>

             <div class="mx-auto w-full">
                  <div class="relative max-w-full h-screen max-h-screen mix-blend-multiply ">
                      <img class="inset-0 w-full h-screen object-cover"  alt="img" src="@/assets/register.png" />                      
                  </div>
              </div>        
    </div>

</template>

<script>

// import Vue from 'vue';
// import mercadopago from 'mercadopago';
// const mp = new MercadoPago('TEST-e1314a4d-b17a-41a2-a71e-c73539d94879');
//  import pago from '../../../libs/mercadopagoV2'

export default {

    name : "processpayment",

    props : {
        idLicense : {
            type : String,
            default : ""
        }
    },

    data(){
        return {

            isloading : false,

        }
    },

    //    mounted() {
            
                // let themejs = document.createElement('script')
                // themejs.setAttribute('src', "https://sdk.mercadopago.com/js/v2")
                // document.head.appendChild(themejs)
    //    },   

    created(){

        // Vue.loadScript("https://sdk.mercadopago.com/js/v2").then(() => {
        //         console.log("accedio")
        //     }).catch(() => {
        //         console.log("no accedio")
        //     });


        // const plugin = document.createElement("script");
        // plugin.setAttribute("src","../../../libs/mercadopagoV2");
        // plugin.async = true;
        // document.head.appendChild(plugin);


        // console.log("1");
        // const mp = new mercadopago.MercadoPago('TEST-e1314a4d-b17a-41a2-a71e-c73539d94879');
        // console.log("2");
        // const pago = mp.cardForm({});

        //  console.log("---");
        //  console.log(pago);
    },

        
    

}
</script>
